.list
{
    display: block;
    margin-top: 2em;
    width: 18em;
    min-width: 18em;
    background-color: hsl(var(--hue), 23.46%, 44.55%);

    border-radius: 1em;
}

.list-name
{
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: hsl(var(--hue), 56.9%, 53.6%);
    width: 10em;
    height: 2em;
    
    border-radius: 0.6em;
    transform: translateY(-1em) translateX(1em);

}

.list-head
{
    display: flex;
    justify-content: space-between;
    margin-right: 1em;
    margin-top: 0.1em;
}