.list-nav-button
{
    background-color: hsl(var(--hue), 56.9%, 53.6%);

    width: 10em;
    height: 2.5em;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    
    transition: background-color 0.05s;
    cursor: pointer;
}

.list-nav-button:hover
{
    background-color: hsl(var(--hue), 56.9%, 45%);
}