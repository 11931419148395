@font-face {
    font-family: roboto;
    src: url("/public/roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: roboto-lt;
    src: url("/public/roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: roboto-th;
    src: url("/public/roboto/Roboto-Thin.ttf");
}

body
{
    margin: 0;
    background-color: black;
    font-family: roboto;
    color: white;
}