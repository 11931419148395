main
{
    display: flex;
    position: absolute;

    top: 0;
    bottom: 4em;
    right: 0;
    left: 0;
    gap: 4em;
}