.list-add
{
    display: inline;

    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.list-add-popup-name
{
    background-color: inherit;
    border: none;
    padding-bottom: 0.5em;
    border-bottom: 4px solid hsl(var(--hue), 90%, 60%);

    font-family: roboto;
    
    outline: none;

    margin-bottom: 2em;
}

.list-add-popup-button
{
    background-color: hsl(var(--hue), 90%, 60%);
    color: black;

    width: 10em;
    height: 2em;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    
    transition: background-color 0.05s;
    cursor: pointer;

    font-family: roboto-lt;

    border: none;
}

.list-add-popup-button:hover
{
    background-color: hsl(var(--hue), 90%, 70%);
}
