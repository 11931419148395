.list-add-popup
{
    display: none;
    background-color: hsl(var(--hue), 70%, 90%);
    position: absolute;
    width: 20em;
    height: 10em;

    border-radius: 2em;

    color: black;
    cursor: auto;
}

.list-add-popup-closebtn
{
    cursor: pointer;
}

.list-add-popup[open]
{
    display: block;
}

.list-add-popup-inside
{
    margin: 1em;
}

.list-add-popup-content
{
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 1em 0 1em;
}