.listItem
{
    display: block;
    height: 1.5em;
    
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.listItem-clear
{
    animation-name: listItem-clearAnimation;
    animation-duration: 1s;
    animation-iteration-count: 1;

}

@keyframes listItem-clearAnimation
{
    0% {opacity: 1; height: 1.5em; transform: translateX(0);}
    50% {height: 0.1em;}
    100% {opacity: 0; height: 0em; transform: translateX(15em);}
}